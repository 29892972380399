<template>
  <v-container fluid>
    <UserHeader>{{ singleUser.surname + " " + singleUser.name }}</UserHeader>
    <UserEditForm :user="singleUser"></UserEditForm>
    <v-card class="mt-7">
      <v-card-title class="px-0 py-7">
        <v-row align="center" class="pl-4">
          <v-col cols="auto">
            <v-card color="#525252" flat height="32px" width="122px">
              <div class="d-flex justify-center align-center">
                <v-icon size="18">$shop</v-icon>
                <div class="font-18px white--text pl-2">
                  店舗一覧
                </div>
              </div>
            </v-card>
          </v-col>
          <v-col md="4" cols="12" offset-md="5" offset-lg="6">
            <TableSearch
              :search.sync="search"
              @update:search="getDataFromApi"
            ></TableSearch>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text class="px-0 pb-0">
        <Table
          :headers="headers"
          :loading="loading"
          :items="getShops"
          :total-records="pagination ? pagination.records_total : 0"
          :number-of-pages="pagination ? pagination.total_pages : 0"
          @update:options="updateTable"
          :items-per-page="itemsPerPage"
          @click:row="
            $router.push({ name: 'ShopsEdit', params: { shop_id: $event.id } })
          "
        >
          <template v-slot:item.id="{ item }">
            <div class="primary--text">{{ item.id }}</div>
          </template>

          <template v-slot:item.name="{ item }">
            <div>{{ item.store_name }}</div>

            <div class="text-furigana-fade font-10px">
              {{ getPrefectureData(item.prefectures) }}
            </div>
          </template>

          <template v-slot:item.company="{ item }">
            {{ getCompany(item.company_type) }}
          </template>

          <template v-slot:item.employees="{ item }">
            {{ getEmployee(item.number_of_employees) }}
          </template>

          <template v-slot:item.fiscal="{ item }">
            <div>{{ getfiscal(item.start_of_fiscal_year) }}年度</div>
            <div class="text-furigana-fade font-10px">
              登記: {{ getRegistrationDate(item.registration_date) }}
            </div>
          </template>

          <template v-slot:item.authority="{ item }">
            {{ item.user_shop[0]?.user_role == "M" ? "マスター" : "パート" }}
          </template>

          <template v-slot:item.status="{ item }">
            <div :class="item.status == 1 ? 'text-green' : 'text-red'">
              {{ item.status == 1 ? "アクティブ" : "インアクティぷ" }}
            </div>
          </template>

          <template v-slot:item.action="{ item }">
            <v-btn color="secondary" icon :to="`/users/${item.id}/edit`">
              <v-icon size="16">$tripleDots</v-icon>
            </v-btn>
          </template>
        </Table>
        <v-divider></v-divider>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import UserHeader from "../components/UserHeader";
import UserEditForm from "../components/UserEditForm";
import Table from "@/components/admin/partials/Table/Table";
import TableSearch from "@/components/admin/partials/Search/TableSearch";
import { mapGetters } from "vuex";
import dayjs from "@/plugins/dayjs";

export default {
  name: "UserEdit",
  components: { UserHeader, UserEditForm, Table, TableSearch },
  created() {
    this.loading = true;
    this.$store
      .dispatch("USER_GET", { id: this.$route.params.id })
      .finally(() => (this.loading = false));
    this.getDataFromApi();
  },
  methods: {
    updateTable(e) {
      if (!this.initialLoad) this.getDataFromApi(e);
    },
    async getDataFromApi(e) {
      let params = {};

      if (e?.sortBy !== undefined && e?.sortDesc !== undefined) {
        params.orderDesc = e.sortDesc[0] ? 0 : 1;
        params.orderBy = e.sortBy[0];
      } else {
        params.orderBy = "id";
        params.orderDesc = 0;
      }

      if (this.search) {
        params.search = this.search;
      }

      params.user_id = this.$route.params.id;

      if (e?.page !== undefined && e?.itemsPerPage !== undefined) {
        params.page = e.page;
        params.paginate = e.itemsPerPage;
      } else {
        params.page = 1;
        params.paginate = 50;
      }

      this.loading = true;
      await this.$store.dispatch("SHOP_GET_ALL", params).then(() => {
        this.loading = false;
        this.initialLoad = false;
      });
    },
    getPrefectureData(prefecture_id) {
      return (
        this.$store.getters.getPrefectures.find(i => i.id === prefecture_id)
          ?.name || ""
      );
    },
    getCompany(company_id) {
      return (
        this.masterData.company_type.find(i => i.id == company_id)?.value || ""
      );
    },
    getEmployee(employee_id) {
      var valObj = this.masterData.number_of_employees.find(
        i => i.id == employee_id
      );

      if (valObj) {
        return valObj.value;
      }

      return "";
    },
    getNoOfShopUsers(ShopUsers) {
      // One-liner
      return (
        ShopUsers.filter(
          elem => elem.user_role === "M" || elem.user_role === "P"
        ).length || 0
      );
    },
    getfiscal(fiscal_year) {
      let currentMonth = parseInt(dayjs().format("MM"));
      if (currentMonth > fiscal_year) {
        return dayjs().format("YYYY");
      } else {
        return dayjs()
          .subtract(1, "year")
          .format("YYYY");
      }
    },
    getRegistrationDate(registration_date) {
      return dayjs(registration_date).format("YYYY-MM");
    }
  },
  computed: {
    ...mapGetters(["singleUser", "getShops"]),
    pagination() {
      return this.$store.getters.ShopPagination;
    },
    masterData() {
      return this.$store.getters.getMasterData;
    }
  },
  data() {
    return {
      headers: [
        { text: this.$t("id"), align: "center", value: "id" },
        {
          text: "店舗名",
          align: "left",
          value: "name",
          sortable: false
        },
        {
          text: "会社形態",
          align: "left",
          value: "company",
          sortable: false
        },
        {
          text: "従業員数",
          align: "left",
          value: "employees",
          sortable: false
        },
        {
          text: "事業年度",
          align: "left",
          value: "fiscal",
          sortable: false
        },
        {
          text: "権限",
          align: "left",
          value: "authority",
          sortable: false
        },
        {
          text: "ステータス",
          align: "left",
          value: "status",
          sortable: false
        },
        {
          text: this.$t("actions"),
          align: "right",
          value: "action",
          sortable: false
        }
      ],
      search: "",
      loading: false,
      initialLoad: true,
      itemsPerPage: 50
    };
  }
};
</script>

<style lang="scss" src="./style.scss" scoped></style>
