<template>
  <v-card>
    <v-card-title class="py-5">
      <v-row>
        <v-col cols="auto">
          <v-btn class="label" text color="transparent">
            <div class="font-weight-regular secondary--text text-capitalize">
              ユーザー情報編集
            </div>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-title>

    <v-divider></v-divider>

    <validation-observer ref="observer">
      <form @submit.prevent="submit" autocomplete="off">
        <v-card-text class="custom-card-text px-0">
          <v-container fluid>
            <v-row>
              <v-col cols="12" md="6">
                <v-row>
                  <v-col
                    align-self="center"
                    cols="2"
                    md="4"
                    class="text-right label-text"
                    >ID</v-col
                  >
                  <v-col
                    align-self="center"
                    cols="4"
                    md="4"
                    class="label-text"
                    >{{ user.id }}</v-col
                  >
                  <v-col align-self="center" cols="12" md="4">
                    <v-avatar
                      color="avatar-bg-grey"
                      size="38"
                      class="white--text avatar-border"
                    >
                      <v-icon v-if="!selectedFile" size="18">$user</v-icon>
                      <v-img
                        v-else
                        :src="!selectedFile ? file : selectedFile"
                        width="40"
                      ></v-img>
                    </v-avatar>
                    <v-btn
                      text
                      color="primary"
                      class="text-capitalize"
                      :loading="isSelecting"
                      @click="handleFileImport"
                    >
                      <span class="plus">+</span>画像ファイル
                    </v-btn>
                    <input
                      ref="uploader"
                      class="d-none"
                      type="file"
                      accept="image/*"
                      @change="onFileChanged"
                    />
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-row>
                  <v-col cols="12" md="6">
                    <v-row align="center">
                      <v-col
                        v-for="field in fields"
                        :key="field.name"
                        cols="12"
                      >
                        <v-row align="center">
                          <v-col
                            cols="4"
                            md="4"
                            align-self="baseline"
                            class="text-right label-text"
                          >
                            <span>
                              {{ field.label }}
                            </span>
                            <span class="required-text ml-1">必須</span>
                          </v-col>

                          <template
                            v-if="
                              field.type === 'text' || field.type === 'email'
                            "
                          >
                            <v-col
                              cols="8"
                              :md="field.additional_field ? '4' : '8'"
                              align-self="baseline"
                            >
                              <validation-provider
                                v-slot="{ errors }"
                                :name="field.name"
                                :rules="field.rules"
                              >
                                <v-text-field
                                  outlined
                                  dense
                                  flat
                                  :type="field.type"
                                  v-model="field.value"
                                  :placeholder="field.placeholder"
                                  autocomplete="chrome-off"
                                  :error-messages="errors"
                                ></v-text-field>
                              </validation-provider>
                            </v-col>
                            <v-col
                              v-if="field.additional_field"
                              cols="8"
                              :md="field.additional_field ? '4' : '8'"
                              offset="4"
                              offset-md="0"
                            >
                              <validation-provider
                                v-slot="{ errors }"
                                :name="field.additional_field.name"
                                :rules="field.additional_field.rules"
                              >
                                <v-text-field
                                  outlined
                                  dense
                                  flat
                                  v-model="field.additional_field.value"
                                  :placeholder="
                                    field.additional_field.placeholder
                                  "
                                  :error-messages="errors"
                                  autocomplete="chrome-off"
                                ></v-text-field>
                              </validation-provider>
                            </v-col>
                          </template>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col cols="12" md="6">
                    <v-row
                      align="center"
                      v-for="field in fieldsRight"
                      :key="field.name"
                    >
                      <v-col
                        cols="3"
                        md="3"
                        align-self="baseline"
                        class="text-right label-text"
                      >
                        <span>
                          {{ field.label }}
                        </span>
                        <span class="required-text ml-1">必須</span>
                      </v-col>

                      <template v-if="field.type === 'text'">
                        <v-col
                          cols="8"
                          :md="field.additional_field ? '4' : '8'"
                          align-self="baseline"
                        >
                          <validation-provider
                            v-slot="{ errors }"
                            :name="field.name"
                            :rules="field.rules"
                          >
                            <v-text-field
                              outlined
                              dense
                              flat
                              :type="field.type"
                              v-model="field.value"
                              :placeholder="field.placeholder"
                              autocomplete="off"
                              :error-messages="errors"
                            ></v-text-field>
                          </validation-provider>
                        </v-col>
                      </template>
                    </v-row>
                    <v-row align="center">
                      <v-col cols="12">
                        <v-row align="center">
                          <v-col
                            cols="3"
                            md="3"
                            align-self="baseline"
                            class="text-right label-text"
                          >
                            <span>{{ $t("password") }}</span>
                          </v-col>
                          <v-col
                            cols="8"
                            align-self="baseline"
                            class="primary--text font-12px"
                          >
                            <v-btn
                              color="primary"
                              text
                              small
                              class="text-capitalize"
                              @click="sendResetEmail"
                            >
                              パスワード再設定用のメールを送信する
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col cols="1" class="text-left">
                    <v-btn
                      text
                      color="#790000"
                      class="text-capitalize font-weight-regular"
                      @click="deleteUser"
                    >
                      削除
                    </v-btn>
                  </v-col>
                  <v-col cols="10" class="text-center">
                    <v-btn
                      type="submit"
                      :disabled="formStatus"
                      :loading="formStatus"
                      color="primary"
                      class="text-capitalize font-weight-regular"
                      depressed
                      small
                      width="90"
                    >
                      保存
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </form>
    </validation-observer>
  </v-card>
</template>

<script>
export default {
  name: "index",
  props: { user: {} },
  data() {
    return {
      valid: false,
      inputPassword: true,
      password: "",
      passwordScore: 0,
      passwordRules: "required:パスワード|password",
      isSelecting: false,
      selectedFile: null,
      file: null,
      fields: [
        {
          label: "登録者名",
          name: "surname",
          type: "text",
          placeholder: "姓",
          value: "",
          rules: "required:姓",
          additional_field: {
            type: "text",
            name: "name",
            placeholder: "名",
            value: "",
            rules: "required:名"
          }
        },
        {
          label: "フリガナ",
          name: "furigana_surname",
          type: "text",
          placeholder: "フリガナ",
          value: "",
          rules: "required:フリガナ",
          additional_field: {
            type: "text",
            name: "furigana_name",
            placeholder: "メイ",
            value: "",
            rules: "required:メイ"
          }
        },
        {
          label: "電話番号",
          name: "telephone_number",
          type: "text",
          placeholder: "電話番号",
          value: "",
          rules: "required:電話番号|enter_half_width_numbers_hyphens"
        }
      ],
      fieldsRight: [
        {
          label: "会社名",
          name: "shop_name",
          type: "text",
          placeholder: "会社名",
          value: "",
          rules: "required:会社名"
        },
        {
          label: "役職",
          name: "position",
          type: "text",
          placeholder: "役職",
          value: "",
          rules: "required:役職"
        },
        {
          label: "メールアドレス(ID)",
          name: "email",
          type: "text",
          placeholder: "メールアドレス",
          value: "",
          rules: "email|required:メールアドレス"
        }
      ],
      selectedGroups: null,
      selectedBusiness: null,
      notificationEmail: false,
      passwordEmail: false,
      formStatus: false
    };
  },
  watch: {
    user: function(val) {
      this.fillFields();
    }
  },
  methods: {
    toggleInputPassword() {
      this.inputPassword = !this.inputPassword;
    },
    deleteUser() {
      this.$root.confirm
        .open(`このユーザーを\n削除してもよろしいですか？`)
        .then(res => {
          if (res) {
            this.confirmDelete();
          }
        });
    },
    confirmDelete() {
      this.formStatus = true;
      this.$store
        .dispatch("DELETE_USER", { id: this.$route.params.id })
        .then(() => {
          this.$store.dispatch("ALERT", {
            show: true,
            text: "編集内容を保存しました。"
          });
          this.$store.dispatch("MASTER_GET_ALL").then(() => {
            this.$router.push({ name: "UserList" });
          });
        })
        .finally(() => {
          this.formStatus = false;
        });
    },
    sendResetEmail() {
      this.formStatus = true;
      let data = {};
      data.email = this.user.email;
      data.reset_url = process.env.VUE_APP_USER_PASSWORD_RESET_URL;

      this.$store.dispatch("USER_ADMINS_PASSWORD_RESET", data).finally(() => {
        this.formStatus = false;
      });
    },
    fillFields() {
      let user = this.user;
      for (const field of this.fields) {
        if (field.additional_field) {
          field.additional_field.value = user[field.additional_field.name];
        }
        field.value = user?.[field.name];
      }

      for (const field of this.fieldsRight) {
        if (field.additional_field) {
          field.additional_field.value = user[field.additional_field.name];
        }
        field.value = user?.[field.name];
      }

      this.selectedFile = user.avatar;
    },
    async submit() {
      this.formStatus = true;
      this.$refs.observer.validate().then(success => {
        if (!success) {
          this.formStatus = false;
          return;
        }

        const data = new FormData();

        this.fields.forEach(field => {
          if ({}.hasOwnProperty.call(field, "additional_field")) {
            data.append(
              field.additional_field.name,
              field.additional_field.value
            );
          }
          data.append(field.name, field.value);
        });

        this.fieldsRight.forEach(field => {
          if ({}.hasOwnProperty.call(field, "additional_field")) {
            data.append(
              field.additional_field.name,
              field.additional_field.value
            );
          }
          data.append(field.name, field.value);
        });

        if (this.file) {
          data.append("avatar", this.file);
        }

        this.$store
          .dispatch("EDIT_USER", {
            id: this.$route.params.id,
            formData: data
          })
          .then(result => {
            if (result.status === 200) this.$refs.observer.reset();
            this.$router.push({ name: "UserList" });
          })
          .catch(error => {
            if (error.status == 422) {
              this.$refs.observer.setErrors(error?.data?.errors);
            }
          })
          .finally(() => {
            this.formStatus = false;
          });
      });
    },
    // Avatar input code
    handleFileImport() {
      this.isSelecting = true;
      // After obtaining the focus when closing the FilePicker, return the button state to normal
      window.addEventListener(
        "focus",
        () => {
          this.isSelecting = false;
        },
        { once: true }
      );

      // Trigger click on the FileInput
      this.$refs.uploader.click();
    },
    onFileChanged(e) {
      this.selectedFile = URL.createObjectURL(e.target.files[0]);
      this.file = e.target.files[0];
      // Do whatever you need with the file, liek reading it with FileReader
    }
  }
};
</script>

<style lang="scss" src="./style.scss" scoped></style>
