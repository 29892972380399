var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('UserHeader', [_vm._v(_vm._s(_vm.singleUser.surname + " " + _vm.singleUser.name))]), _c('UserEditForm', {
    attrs: {
      "user": _vm.singleUser
    }
  }), _c('v-card', {
    staticClass: "mt-7"
  }, [_c('v-card-title', {
    staticClass: "px-0 py-7"
  }, [_c('v-row', {
    staticClass: "pl-4",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-card', {
    attrs: {
      "color": "#525252",
      "flat": "",
      "height": "32px",
      "width": "122px"
    }
  }, [_c('div', {
    staticClass: "d-flex justify-center align-center"
  }, [_c('v-icon', {
    attrs: {
      "size": "18"
    }
  }, [_vm._v("$shop")]), _c('div', {
    staticClass: "font-18px white--text pl-2"
  }, [_vm._v(" 店舗一覧 ")])], 1)])], 1), _c('v-col', {
    attrs: {
      "md": "4",
      "cols": "12",
      "offset-md": "5",
      "offset-lg": "6"
    }
  }, [_c('TableSearch', {
    attrs: {
      "search": _vm.search
    },
    on: {
      "update:search": [function ($event) {
        _vm.search = $event;
      }, _vm.getDataFromApi]
    }
  })], 1)], 1)], 1), _c('v-card-text', {
    staticClass: "px-0 pb-0"
  }, [_c('Table', {
    attrs: {
      "headers": _vm.headers,
      "loading": _vm.loading,
      "items": _vm.getShops,
      "total-records": _vm.pagination ? _vm.pagination.records_total : 0,
      "number-of-pages": _vm.pagination ? _vm.pagination.total_pages : 0,
      "items-per-page": _vm.itemsPerPage
    },
    on: {
      "update:options": _vm.updateTable,
      "click:row": function clickRow($event) {
        return _vm.$router.push({
          name: 'ShopsEdit',
          params: {
            shop_id: $event.id
          }
        });
      }
    },
    scopedSlots: _vm._u([{
      key: "item.id",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('div', {
          staticClass: "primary--text"
        }, [_vm._v(_vm._s(item.id))])];
      }
    }, {
      key: "item.name",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_c('div', [_vm._v(_vm._s(item.store_name))]), _c('div', {
          staticClass: "text-furigana-fade font-10px"
        }, [_vm._v(" " + _vm._s(_vm.getPrefectureData(item.prefectures)) + " ")])];
      }
    }, {
      key: "item.company",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_vm._v(" " + _vm._s(_vm.getCompany(item.company_type)) + " ")];
      }
    }, {
      key: "item.employees",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_vm._v(" " + _vm._s(_vm.getEmployee(item.number_of_employees)) + " ")];
      }
    }, {
      key: "item.fiscal",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [_c('div', [_vm._v(_vm._s(_vm.getfiscal(item.start_of_fiscal_year)) + "年度")]), _c('div', {
          staticClass: "text-furigana-fade font-10px"
        }, [_vm._v(" 登記: " + _vm._s(_vm.getRegistrationDate(item.registration_date)) + " ")])];
      }
    }, {
      key: "item.authority",
      fn: function fn(_ref6) {
        var _item$user_shop$;

        var item = _ref6.item;
        return [_vm._v(" " + _vm._s(((_item$user_shop$ = item.user_shop[0]) === null || _item$user_shop$ === void 0 ? void 0 : _item$user_shop$.user_role) == "M" ? "マスター" : "パート") + " ")];
      }
    }, {
      key: "item.status",
      fn: function fn(_ref7) {
        var item = _ref7.item;
        return [_c('div', {
          "class": item.status == 1 ? 'text-green' : 'text-red'
        }, [_vm._v(" " + _vm._s(item.status == 1 ? "アクティブ" : "インアクティぷ") + " ")])];
      }
    }, {
      key: "item.action",
      fn: function fn(_ref8) {
        var item = _ref8.item;
        return [_c('v-btn', {
          attrs: {
            "color": "secondary",
            "icon": "",
            "to": "/users/".concat(item.id, "/edit")
          }
        }, [_c('v-icon', {
          attrs: {
            "size": "16"
          }
        }, [_vm._v("$tripleDots")])], 1)];
      }
    }])
  }), _c('v-divider')], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }