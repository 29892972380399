var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', [_c('v-card-title', {
    staticClass: "py-5"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "label",
    attrs: {
      "text": "",
      "color": "transparent"
    }
  }, [_c('div', {
    staticClass: "font-weight-regular secondary--text text-capitalize"
  }, [_vm._v(" ユーザー情報編集 ")])])], 1)], 1)], 1), _c('v-divider'), _c('validation-observer', {
    ref: "observer"
  }, [_c('form', {
    attrs: {
      "autocomplete": "off"
    },
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.submit($event);
      }
    }
  }, [_c('v-card-text', {
    staticClass: "custom-card-text px-0"
  }, [_c('v-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-row', [_c('v-col', {
    staticClass: "text-right label-text",
    attrs: {
      "align-self": "center",
      "cols": "2",
      "md": "4"
    }
  }, [_vm._v("ID")]), _c('v-col', {
    staticClass: "label-text",
    attrs: {
      "align-self": "center",
      "cols": "4",
      "md": "4"
    }
  }, [_vm._v(_vm._s(_vm.user.id))]), _c('v-col', {
    attrs: {
      "align-self": "center",
      "cols": "12",
      "md": "4"
    }
  }, [_c('v-avatar', {
    staticClass: "white--text avatar-border",
    attrs: {
      "color": "avatar-bg-grey",
      "size": "38"
    }
  }, [!_vm.selectedFile ? _c('v-icon', {
    attrs: {
      "size": "18"
    }
  }, [_vm._v("$user")]) : _c('v-img', {
    attrs: {
      "src": !_vm.selectedFile ? _vm.file : _vm.selectedFile,
      "width": "40"
    }
  })], 1), _c('v-btn', {
    staticClass: "text-capitalize",
    attrs: {
      "text": "",
      "color": "primary",
      "loading": _vm.isSelecting
    },
    on: {
      "click": _vm.handleFileImport
    }
  }, [_c('span', {
    staticClass: "plus"
  }, [_vm._v("+")]), _vm._v("画像ファイル ")]), _c('input', {
    ref: "uploader",
    staticClass: "d-none",
    attrs: {
      "type": "file",
      "accept": "image/*"
    },
    on: {
      "change": _vm.onFileChanged
    }
  })], 1)], 1)], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, _vm._l(_vm.fields, function (field) {
    return _c('v-col', {
      key: field.name,
      attrs: {
        "cols": "12"
      }
    }, [_c('v-row', {
      attrs: {
        "align": "center"
      }
    }, [_c('v-col', {
      staticClass: "text-right label-text",
      attrs: {
        "cols": "4",
        "md": "4",
        "align-self": "baseline"
      }
    }, [_c('span', [_vm._v(" " + _vm._s(field.label) + " ")]), _c('span', {
      staticClass: "required-text ml-1"
    }, [_vm._v("必須")])]), field.type === 'text' || field.type === 'email' ? [_c('v-col', {
      attrs: {
        "cols": "8",
        "md": field.additional_field ? '4' : '8',
        "align-self": "baseline"
      }
    }, [_c('validation-provider', {
      attrs: {
        "name": field.name,
        "rules": field.rules
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function fn(_ref) {
          var errors = _ref.errors;
          return [_c('v-text-field', {
            attrs: {
              "outlined": "",
              "dense": "",
              "flat": "",
              "type": field.type,
              "placeholder": field.placeholder,
              "autocomplete": "chrome-off",
              "error-messages": errors
            },
            model: {
              value: field.value,
              callback: function callback($$v) {
                _vm.$set(field, "value", $$v);
              },
              expression: "field.value"
            }
          })];
        }
      }], null, true)
    })], 1), field.additional_field ? _c('v-col', {
      attrs: {
        "cols": "8",
        "md": field.additional_field ? '4' : '8',
        "offset": "4",
        "offset-md": "0"
      }
    }, [_c('validation-provider', {
      attrs: {
        "name": field.additional_field.name,
        "rules": field.additional_field.rules
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function fn(_ref2) {
          var errors = _ref2.errors;
          return [_c('v-text-field', {
            attrs: {
              "outlined": "",
              "dense": "",
              "flat": "",
              "placeholder": field.additional_field.placeholder,
              "error-messages": errors,
              "autocomplete": "chrome-off"
            },
            model: {
              value: field.additional_field.value,
              callback: function callback($$v) {
                _vm.$set(field.additional_field, "value", $$v);
              },
              expression: "field.additional_field.value"
            }
          })];
        }
      }], null, true)
    })], 1) : _vm._e()] : _vm._e()], 2)], 1);
  }), 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_vm._l(_vm.fieldsRight, function (field) {
    return _c('v-row', {
      key: field.name,
      attrs: {
        "align": "center"
      }
    }, [_c('v-col', {
      staticClass: "text-right label-text",
      attrs: {
        "cols": "3",
        "md": "3",
        "align-self": "baseline"
      }
    }, [_c('span', [_vm._v(" " + _vm._s(field.label) + " ")]), _c('span', {
      staticClass: "required-text ml-1"
    }, [_vm._v("必須")])]), field.type === 'text' ? [_c('v-col', {
      attrs: {
        "cols": "8",
        "md": field.additional_field ? '4' : '8',
        "align-self": "baseline"
      }
    }, [_c('validation-provider', {
      attrs: {
        "name": field.name,
        "rules": field.rules
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function fn(_ref3) {
          var errors = _ref3.errors;
          return [_c('v-text-field', {
            attrs: {
              "outlined": "",
              "dense": "",
              "flat": "",
              "type": field.type,
              "placeholder": field.placeholder,
              "autocomplete": "off",
              "error-messages": errors
            },
            model: {
              value: field.value,
              callback: function callback($$v) {
                _vm.$set(field, "value", $$v);
              },
              expression: "field.value"
            }
          })];
        }
      }], null, true)
    })], 1)] : _vm._e()], 2);
  }), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "text-right label-text",
    attrs: {
      "cols": "3",
      "md": "3",
      "align-self": "baseline"
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.$t("password")))])]), _c('v-col', {
    staticClass: "primary--text font-12px",
    attrs: {
      "cols": "8",
      "align-self": "baseline"
    }
  }, [_c('v-btn', {
    staticClass: "text-capitalize",
    attrs: {
      "color": "primary",
      "text": "",
      "small": ""
    },
    on: {
      "click": _vm.sendResetEmail
    }
  }, [_vm._v(" パスワード再設定用のメールを送信する ")])], 1)], 1)], 1)], 1)], 2), _c('v-col', {
    staticClass: "text-left",
    attrs: {
      "cols": "1"
    }
  }, [_c('v-btn', {
    staticClass: "text-capitalize font-weight-regular",
    attrs: {
      "text": "",
      "color": "#790000"
    },
    on: {
      "click": _vm.deleteUser
    }
  }, [_vm._v(" 削除 ")])], 1), _c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "10"
    }
  }, [_c('v-btn', {
    staticClass: "text-capitalize font-weight-regular",
    attrs: {
      "type": "submit",
      "disabled": _vm.formStatus,
      "loading": _vm.formStatus,
      "color": "primary",
      "depressed": "",
      "small": "",
      "width": "90"
    }
  }, [_vm._v(" 保存 ")])], 1)], 1)], 1)], 1)], 1)], 1)], 1)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }